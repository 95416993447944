<template>
    <mega-modal ref="modal-2" class="modal-sm"
                :active="modal"
                @onClose="modal = false">

        <mega-header :title="$t('new_model')" class="bg-lighter">
            <span class="text-muted">{{ $t('new_model_desc') }}</span>
        </mega-header>

        <div class="card-body">

            <mega-input :label="$t('new_model_name')" :valid="name.length > 0" v-model="name"/>

            <mega-radio
				:label="$t('round_type')"
				:options="roundTypes"
				v-model="round_type"
				:current="round_type"
				@dropgrp="group = 0" />
        </div>

        <div class="card-body">
            <div class="navbar">
                <div class="navbar-group ml-auto">
                    <mega-button class="btn-white mr-2" @click="modal = false">{{ $t('close') }}</mega-button>
                    <mega-button class="btn-primary mr-2" :disabled="!name || round_type === undefined" @click="submit">{{ $t('save') }}</mega-button>
                </div>
            </div>
        </div>
    </mega-modal>
</template>

<script>
    import RoundTypes from '../../data/round_types.json'

    export default {
        data() {
            return {
                name: '',
                arp: '',
                round_type: undefined,
                modal: false,
                roundTypes: RoundTypes
            }
        },
        computed: {
            type() {
              let res = undefined;

              if(this.round_type === 2) res = 'Image';
              if(this.round_type === 3) res = 'Video';
              if(this.round_type === 4) res = 'Model';
              if(this.round_type === 5) res = 'Gif';
              if(this.round_type === 10) res = 'Mask';

              return res;
            },
        },
        methods: {

            submit() {

                let data = {
                    idc_round_type: this.round_type,
                    name: this.name,
                    idt_arp: parseInt(this.arp),
                };

                this.$api.v2.post('/user/model', data)
                    .then((response) => {
                        let model_id = response.data.model.idt_model;

                        let formData = new FormData();
                        formData.append("idt_model", model_id);
                        formData.append("name", this.name);

                        this.$api.v2.post('/user/modif', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                            .then(( _data ) => {
                                console.log(_data);
                                this.$router.push({name: this.type, params: {
                                    arr_id: data.idt_arp,
                                    model_id: model_id,
                                    modification_id: _data.data.modif.idt_model_modif
                                }});
                                this.modal = false;
                            }).catch(err => console.log(err))
                    })
                    .catch(() => { this.$alert.danger('Oops.. Server error') })
            },

            capitalize(str) {
                return str.charAt(0).toUpperCase() + str.slice(1);
            },

            open(params) {
                this.round_type = undefined;
                this.name = '';
                this.arp = params.arp;

                this.modal = true;

                if(!params) return false;
                if(params.type) this.round_type = params.type;
            }
        }
    }
</script>
