var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mega-modal",
    {
      staticClass: "modal-sm",
      attrs: { active: _vm.modal, center: "" },
      on: { onClose: _vm.close }
    },
    [
      _vm.modal
        ? _c("div", [
            _vm.loading
              ? _c("div", { staticClass: "await loading" })
              : _vm._e(),
            _vm._v(" "),
            _c("div", {
              style: {
                backgroundImage: "url(" + _vm.src + ")",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                paddingBottom: _vm.ratio ? _vm.padding + "%" : ""
              }
            })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }