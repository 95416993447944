var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "models-page" },
    [
      _c(
        "section",
        {
          staticClass: "border-bottom bg-lighter md-up:sticky-top",
          staticStyle: { top: "70px" }
        },
        [
          _c("div", { staticClass: "container py-3" }, [
            _c("nav", { staticClass: "nav sm-down:flex-column" }, [
              _c(
                "span",
                {
                  staticClass:
                    "btn btn-sm btn-light text-left d-block sm-down:w-fill",
                  on: { click: _vm.addModel }
                },
                [
                  _c("i", { staticClass: "i-package float-left" }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("new_model")))])
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("section", [
        _c(
          "div",
          { staticClass: "container py-5" },
          [
            _c(
              "nav",
              {
                staticClass: "nav sm-down:flex-column mb-3",
                staticStyle: { padding: "3px 0" }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "btn text-left d-block sm-down:w-fill",
                    class: { "btn-primary": _vm.is_active === "all" },
                    on: {
                      click: function($event) {
                        _vm.active_bool = undefined
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("all")))]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "btn text-left d-block sm-down:w-fill",
                    class: { "btn-primary": _vm.is_active === "active" },
                    on: {
                      click: function($event) {
                        _vm.active_bool = true
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("active")))]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "btn text-left d-block sm-down:w-fill",
                    class: { "btn-primary": _vm.is_active === "disabled" },
                    on: {
                      click: function($event) {
                        _vm.active_bool = false
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("disabled")))]
                )
              ]
            ),
            _vm._v(" "),
            _vm.list.length
              ? _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.list, function(model, i) {
                    return model.modifications && model.modifications
                      ? _c(
                          "div",
                          {
                            key: i,
                            staticClass:
                              "sm-down:col-12 md:col-6 lg:col-4 xl:col-3 mb-4"
                          },
                          [
                            _c("model-card", {
                              attrs: { data: model },
                              on: { activeToggle: _vm.clearData }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c("mega-loading", {
              attrs: { identifier: _vm.loadingId },
              on: {
                infinite: function($event) {
                  return _vm.loadPage(_vm.active_bool, $event)
                }
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("new-model", { ref: "new-model" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }