<template>
  <main class="models-page">

    <section class="border-bottom bg-lighter md-up:sticky-top" style="top: 70px;">
      <div class="container py-3">
        <nav class="nav sm-down:flex-column">

          <span class="btn btn-sm btn-light text-left d-block sm-down:w-fill" @click="addModel">
            <i class="i-package float-left"></i>
            <span>{{ $t('new_model') }}</span>
          </span>
        </nav>
      </div>
    </section>

    <section>
      <div class="container py-5">

        <nav class="nav sm-down:flex-column mb-3" style="padding: 3px 0;">

            <span class="btn text-left d-block sm-down:w-fill"
                  :class="{'btn-primary': is_active === 'all'}"
                  @click="active_bool = undefined">{{ $t('all') }}</span>

          <span class="btn text-left d-block sm-down:w-fill"
                :class="{'btn-primary': is_active === 'active'}"
                @click="active_bool = true">{{ $t('active') }}</span>

          <span class="btn text-left d-block sm-down:w-fill"
                :class="{'btn-primary': is_active === 'disabled'}"
                @click="active_bool = false">{{ $t('disabled') }}</span>
        </nav>

        <div class="row" v-if="list.length">
          <div class="sm-down:col-12 md:col-6 lg:col-4 xl:col-3 mb-4" v-for="(model, i) in list" :key="i" v-if="model.modifications && model.modifications">
            <model-card :data="model" @activeToggle="clearData"/>
          </div>
        </div>

        <mega-loading :identifier="loadingId" @infinite="loadPage(active_bool, $event)"></mega-loading>
      </div>
    </section>

      <new-model ref="new-model"/>
  </main>
</template>

<script>
  import ModelCard from './components/model-card'
  import NewModel from '../../components/new_model'

  export default {
    name: 'ModelsPage',
    props: ['arr_id', 'round_type', 'is_active'],
    data() {
      return {
		  loadingId: +new Date(),
	  }
    },
    computed: {
      list() {
        return this.$root.list;
      },
      active_bool: {
        get() {
          let act = undefined;

          if(this.is_active === 'active')
            act = true;

          if(this.is_active === 'disabled')
            act = false;

          return act;
        },
        set(value) {
          let query = this.$route.query;
          let params = { is_active: value === undefined ? 'all' : value ? 'active' : 'disabled' };

          this.$router.push({query, params});
        }
      },
      type_id() {
        let res = undefined;

        if (this.round_type === 'image') res = 2;
        if (this.round_type === 'video') res = 3;
        if (this.round_type === '3d') res = 4;
        if (this.round_type === 'gif') res = 5;
        if (this.round_type === 'mask') res = 10;

        return res;
      }
    },
    watch: {
      '$route'() {
        this.clearData();
      }
	},
	created() {
		this.$root.list.splice(0, this.$root.list.length);
	},
    mounted() {
		  this.$navbar.name = 'Models';
    },
    methods: {

      addModel() { this.$refs['new-model'].open({arp: this.arr_id}) },

      loadPage(is_active, $state) {

        let params = {
          is_active,
          limit: 60,
          idt_arp: this.arr_id,
          idc_round_type: this.type_id,
          offset: this.list.length,
          is_active_enable: true,
          modif_is_active_enable: true
        };

        this.$api.v2.get('/common/models', {params})
          .then(({data}) => {

            this.length = data.count;

            if(data.models.length) {
              setTimeout(() => { if ($state) $state.loaded() }, 500);
              this.$root.list.push(...data.models);
              this.$navbar.name = `${ this.$t('models') } (${ this.list.length })`;
            }
            else if ($state) $state.complete();
          })
          .catch(() => { this.$alert.danger('Oops.. Server error') })
	  },
	  
	  clearData() {
        this.$root.list.splice(0, this.$root.list.length);
        this.loadingId += 1;
      }
    },
    components: {
      ModelCard,
      NewModel
    }
  }
</script>
